import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialogWithLanguage from "components/FullPageDialogWithLanguage";
import Stack from "components/Stack";
import Text from "components/Text";

import PrimaryContactVerifyInformationForm, {
  VerifyPrimaryInformationRef,
} from "entities/PrimaryContact/components/VerifyInformationForm";

import theme from "theme";
import { URLS } from "config/urls";

const ContactInfoPage = () => {
  const { t } = useTranslation();
  const formRef = useRef<VerifyPrimaryInformationRef>(null);
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
      navigate(URLS.VERIFY_INCOME_CHILD_CARE_NEEDS);
    }
  };

  return (
    <FullPageDialogWithLanguage>
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Stack spacing={2} sx={{ height: "100%" }}>
          <Text variant="titleLarge">{t("confirmContactInfo")}</Text>
          <div style={{ flex: 1 }}>
            <PrimaryContactVerifyInformationForm ref={formRef} />
          </div>
          <div
            style={{
              padding: theme.spacing(2, 3),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasicButton
              label={t("next")}
              onClick={handleFormSubmit}
            ></BasicButton>
          </div>
        </Stack>
      </Container>
    </FullPageDialogWithLanguage>
  );
};

export default ContactInfoPage;
