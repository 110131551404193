import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialogWithLanguage from "components/FullPageDialogWithLanguage";
import Stack from "components/Stack";

import ChildCareNeedsForm, {
  ChildCareNeedsRef,
} from "entities/Family/components/ChildCareNeedsForm";

import theme from "theme";

import { URLS } from "config/urls";

const ChildCareNeedsPage = () => {
  const { t } = useTranslation();
  const formRef = useRef<ChildCareNeedsRef>(null);
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
      navigate(URLS.VERIFY_INCOME_OVERVIEW);
    }
  };

  return (
    <FullPageDialogWithLanguage>
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Stack
          spacing={2}
          sx={{ height: "100%", justifyContent: "space-between" }}
        >
          <ChildCareNeedsForm ref={formRef} />
          <div
            style={{
              padding: theme.spacing(2, 3),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasicButton label={t("next")} onClick={handleFormSubmit} />
          </div>
        </Stack>
      </Container>
    </FullPageDialogWithLanguage>
  );
};

export default ChildCareNeedsPage;
