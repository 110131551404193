import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";

import { EditIcon } from "icons";
import theme, { colors } from "theme";

type IProps = {
  name: string;
  onEdit: () => void;
};

const ParentRow = ({ name, onEdit }: IProps) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="space-between"
    sx={{
      borderTop: "1px solid",
      borderBottom: "1px solid",
      borderColor: colors.outline,
      padding: theme.spacing(1),
      width: "100%",
    }}
  >
    <Stack gap="15px">
      <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
        {name.trim() === "" && "Unknown name"}
        {name}
      </Text>
    </Stack>
    <IconButton
      sx={{
        height: "40px",
        width: "40px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onEdit();
      }}
    >
      <EditIcon />
    </IconButton>
  </Stack>
);

export default ParentRow;
