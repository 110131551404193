import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import IconButton from "components/IconButton";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";

import { useMyFamily } from "entities/Family/sdk";
import { getEmploymentStatusDisplayValue } from "entities/FamilyMember/sdk";

import theme, { colors } from "theme";
import { EditIcon, AttentionIcon } from "icons";
import { reverse } from "utils/urls";
import { URLS } from "config/urls";

const ParentsListPage = () => {
  const { t } = useTranslation();
  const { data: family } = useMyFamily();
  const parents = family?.family_members;

  const parentsMissingFields = family?.missing_fields
    .flatMap((voucher) => voucher.missing_fields.parent_missing_fields)
    .filter(
      (parentMissingFields) => parentMissingFields.missing_fields.length > 0
    );

  return (
    <FullPageDialog
      title={t("parentStatus")}
      progressBarPercentage={20}
      backUrl={URLS.MISSING_INFORMATION_PRIMARY_CONTACT}
      closeUrl={URLS.HOME}
    >
      <Stack sx={{ justifyContent: "space-between", flex: 1 }}>
        <Container maxWidth="sm" sx={{ flex: 1, display: "flex" }}>
          <Stack sx={{ mt: "30px", width: "100%", alignItems: "center" }}>
            <Stack
              sx={{
                marginBottom: "20px",
                borderBottom: "1px solid",
                borderColor: colors.outline,
                width: "100%",
              }}
            >
              {parents?.map((parent, index) => (
                <Stack
                  key={index}
                  sx={{
                    paddingTop: (theme) => theme.spacing(1),
                    paddingBottom: (theme) => theme.spacing(1),
                    borderTop: "1px solid",
                    borderColor: colors.outline,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  direction="row"
                >
                  <div>
                    {parentsMissingFields?.find(
                      (pmf) => pmf.id === parent.id
                    ) && (
                      <AttentionIcon
                        style={{
                          width: "12.5px",
                          height: "12.5px",
                          marginRight: "10px",
                        }}
                      />
                    )}
                    <Text variant="titleSmall">
                      {!parent.first_name &&
                        !parent.last_name &&
                        "Unknown name"}
                      {parent.first_name} {parent.last_name}
                    </Text>
                  </div>
                  {parentsMissingFields?.find(
                    (pmf) => pmf.id === parent.id
                  ) && (
                    <Text sx={{ color: colors.error }}>
                      Missing Information
                    </Text>
                  )}
                  <div>
                    <Text variant="titleSmall">
                      <Text variant="titleSmall" fontWeight="bold">
                        {parent.employment_status &&
                          getEmploymentStatusDisplayValue(
                            parent.employment_status
                          )}
                      </Text>{" "}
                      {parent.employer_name} {parent.other_status_description}
                    </Text>
                    <Link
                      to={reverse(URLS.MISSING_INFORMATION_EDIT_PARENT, {
                        parentId: parent.id,
                      })}
                    >
                      <IconButton size="small">
                        <EditIcon style={{ width: "18px", height: "18px" }} />
                      </IconButton>
                    </Link>
                  </div>
                </Stack>
              ))}
            </Stack>
            <Link
              to={URLS.MISSING_INFORMATION_ADD_PARENT}
              style={{ display: "content" }}
            >
              <AddButton
                label={t("addParent")}
                backgroundColor={colors.secondaryContainer}
              />
            </Link>
          </Stack>
        </Container>
        <Stack
          sx={{
            margin: theme.spacing(4, 0),
            alignItems: "center",
            borderTop: "1px solid " + colors.outlineVariant,
            padding: (theme) => theme.spacing(3, 0),
          }}
        >
          <Link to={URLS.MISSING_INFORMATION_CHILDREN}>
            <BasicButton label={t("next")} />
          </Link>
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default ParentsListPage;
