import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import Container from "components/Container";
import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import DatePicker from "components/DatePicker";
import Box from "components/Box";
import useMediaQuery from "components/useMediaQuery";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

import {
  useGenderOptions,
  useLanguageOptions,
  useRelationshipOptions,
} from "entities/FamilyMember/sdk";

import { getDefaultLanguageValue } from "utils/language";
import DateInput from "components/DateInput";

import theme, { colors } from "theme";
import { useFormWarnings } from "utils/forms";

interface FormData {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender: "MEN" | "WOMEN";
  relationship: "naturalChild" | "fosterChild" | "guardianChild";
  date_when_child_care_is_needed: string;
  preferred_language: "english" | "spanish" | "capeverdean" | "haitian";
  child_has_a_disability: boolean;
  child_is_in_head_start_and_needs_more_hours_of_coverage: boolean;
}

interface IProps {
  initialValues?: Partial<FormData>;
  fieldsWithWarnings?: string[];
  onSave: (values: Partial<FormData>) => Promise<any>;
}

const ChildForm = ({ initialValues, fieldsWithWarnings, onSave }: IProps) => {
  const { t, i18n } = useTranslation();
  const defaultLanguage = getDefaultLanguageValue(i18n.language);
  const matches = useMediaQuery("(min-width:600px)");

  const genderOptions = useGenderOptions();
  const languageOptions = useLanguageOptions();
  const relationshipOptions = useRelationshipOptions();

  const { control, handleSubmit, formState, setError } = useForm<FormData>({
    defaultValues: {
      first_name: initialValues?.first_name || "",
      last_name: initialValues?.last_name || "",
      date_of_birth: initialValues?.date_of_birth
        ? dayjs(initialValues?.date_of_birth).format("MM/DD/YYYY")
        : undefined,
      gender: initialValues?.gender,
      relationship: initialValues?.relationship,
      date_when_child_care_is_needed:
        initialValues?.date_when_child_care_is_needed
          ? dayjs(initialValues.date_when_child_care_is_needed).format(
              "MM/DD/YYYY"
            )
          : dayjs().format("MM/DD/YYYY"),
      preferred_language: initialValues?.preferred_language,
      child_has_a_disability: initialValues?.child_has_a_disability || false,
      child_is_in_head_start_and_needs_more_hours_of_coverage:
        initialValues?.child_is_in_head_start_and_needs_more_hours_of_coverage ||
        false,
    },
  });
  const { fieldWarningsAndErrors } = useFormWarnings({
    formState,
    fieldsWithWarnings,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data: FormData) => {
    const formattedBirthDate = data.date_of_birth
      ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
      : undefined;

    const formattedDateWhenChildcareNeeded = data.date_when_child_care_is_needed
      ? dayjs(data.date_when_child_care_is_needed).format("YYYY-MM-DD")
      : undefined;

    const childData = {
      ...data,
      date_of_birth: formattedBirthDate,
      date_when_child_care_is_needed: formattedDateWhenChildcareNeeded,
    };

    setIsSubmitting(true);
    onSave(childData)
      .catch((errors) => {
        errors.setFormErrors && errors.setFormErrors(setError);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Stack alignItems={"center"} sx={{ flex: 1 }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack
            sx={{ flex: 1 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Container
              maxWidth="sm"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                flex: 1,
                height: "100%",
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("firstName")}
                      fullWidth
                      margin="normal"
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("lastName")}
                      fullWidth
                      margin="normal"
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <StyledSelect
                      {...field}
                      label={t("gender")}
                      options={genderOptions}
                      fullWidth
                      margin="normal"
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />

                <Controller
                  name="preferred_language"
                  control={control}
                  defaultValue={defaultLanguage}
                  render={({ field }) => (
                    <StyledSelect
                      {...field}
                      label={t("preferredLanguage")}
                      options={languageOptions}
                      fullWidth
                      margin="normal"
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />
                <Controller
                  name="date_of_birth"
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      {...field}
                      control={control}
                      label={t("dateOfBirth")}
                      required={false}
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />

                <Controller
                  name="relationship"
                  control={control}
                  defaultValue={relationshipOptions[0].value}
                  render={({ field }) => (
                    <StyledSelect
                      {...field}
                      label={t("relationship")}
                      options={relationshipOptions}
                      fullWidth
                      margin="normal"
                      {...fieldWarningsAndErrors(field)}
                    />
                  )}
                />
                <Controller
                  name="date_when_child_care_is_needed"
                  control={control}
                  render={({ field }) => (
                    <Box sx={{ mt: "16px", mb: "8px" }}>
                      <DatePicker
                        {...field}
                        label={t("dateChildCare")}
                        initialValue={field.value}
                      />
                      {/*!!formState.errors.date_when_child_care_is_needed && (
                      <FormHelperText error>
                        {formState.errors.date_when_child_care_is_needed?.message}
                      </FormHelperText>
                    )*/}
                    </Box>
                  )}
                />
                <Controller
                  name="child_has_a_disability"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("childHasADisability")}
                    />
                  )}
                />
                <Controller
                  name="child_is_in_head_start_and_needs_more_hours_of_coverage"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} checked={field.value} />}
                      label={t("chilsIsInHeadStart")}
                    />
                  )}
                />
              </Stack>
            </Container>
            <Stack
              sx={{
                width: "100%",
                borderTop: matches ? "1px solid" : "none",
                borderColor: colors.outline,
                alignItems: "center",
              }}
            >
              <Stack
                sx={{ margin: theme.spacing(4, 0), width: "100%" }}
                alignItems="center"
              >
                <BasicButton
                  buttonType="submit"
                  label={t("next")}
                  isDisabled={isSubmitting}
                />
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default ChildForm;
