import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import Container from "components/Container";
import BasicButton from "components/BasicButton";
import AddButton from "components/AddButton";
import Link from "components/Link";
import FormHelperText from "components/FormHelperText";
import IconButton from "components/IconButton";

import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import theme, { colors } from "theme";
import { AttentionIcon, EditIcon } from "icons";
import { reverse } from "utils/urls";

const Children = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: family } = useMyFamily();

  const children = family?.children;

  const childrenMissingFields = family?.missing_fields
    .flatMap((voucher) => voucher.missing_fields.child_missing_fields)
    .filter(
      (parentMissingFields) => parentMissingFields.missing_fields.length > 0
    );

  const [showError, setShowError] = useState(false);

  const handleNextClick = () => {
    if (children && children.length === 0) {
      setShowError(true);
    } else {
      navigate(URLS.MISSING_INFORMATION_VERIFICATION);
    }
  };

  return (
    <FullPageDialog
      title={t("childInformation")}
      progressBarPercentage={30}
      backUrl={URLS.MISSING_INFORMATION_PARENTS}
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text variant="titleMedium" sx={{ m: 2 }}>
            {t("childrenHeader")}
          </Text>
          <Stack
            sx={{
              mb: "20px",
              borderBottom: "1px solid",
              borderColor: colors.outline,
              width: "100%",
            }}
          >
            {children?.map((child, index) => (
              <Stack
                key={index}
                sx={{
                  py: 1,
                  borderTop: "1px solid",
                  borderColor: colors.outline,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                direction="row"
              >
                <div>
                  {childrenMissingFields?.find(
                    (cmf) => cmf.id === child.id
                  ) && (
                    <AttentionIcon
                      style={{
                        width: "12.5px",
                        height: "12.5px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                  <Text variant="titleMedium">
                    {!child.first_name && !child.last_name && "Unknown name"}
                    {child.first_name} {child.last_name}
                  </Text>
                </div>
                {childrenMissingFields?.find((cmf) => cmf.id === child.id) && (
                  <Text sx={{ color: colors.error }}>Missing Information</Text>
                )}
                <div>
                  <Text variant="titleMedium">{child.age} yrs</Text>
                  <Link
                    to={reverse(URLS.MISSING_INFORMATION_EDIT_CHILD, {
                      childId: child.id,
                    })}
                  >
                    <IconButton size="small">
                      <EditIcon style={{ width: "18px", height: "18px" }} />
                    </IconButton>
                  </Link>
                </div>
              </Stack>
            ))}
          </Stack>
          <Link to={URLS.MISSING_INFORMATION_ADD_CHILD}>
            <AddButton
              label={t("addChild")}
              backgroundColor={colors.secondaryContainer}
            />
          </Link>
          {showError && (
            <FormHelperText error sx={{ mt: "20px" }}>
              {t("youMustAddAtLeastOneChild")}
            </FormHelperText>
          )}
        </Container>
        <Stack
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: theme.spacing(4, 0),
            alignItems: "center",
          }}
        >
          <BasicButton label={t("next")} onClick={handleNextClick} />
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default Children;
