import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import AddButton from "components/AddButton";
import ParentRow from "components/ParentRow";

import theme, { colors } from "theme";

import { URLS } from "config/urls";

import { useMyFamily } from "entities/Family/sdk";

const ParentsListPage = () => {
  const { t } = useTranslation();
  const { data: familyData } = useMyFamily();
  const navigate = useNavigate();

  const handleEditParent = (parentId: number) => {
    console.log(parentId);
  };

  const handleNextClick = () => {
    navigate(URLS.VERIFY_INCOME_CHILDREN_LIST);
  };

  if (!familyData) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("parents")}
      backUrl={URLS.VERIFY_INCOME_OVERVIEW}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Stack
          spacing={2}
          sx={{ height: "100%", justifyContent: "space-between" }}
        >
          <div style={{ marginTop: theme.spacing(2) }}>
            <Text
              component="p"
              variant="titleMedium"
              color={colors.onSurface}
              sx={{ mb: theme.spacing(2) }}
            >
              {t("addParentsInstruction")}
            </Text>
            <Text component="p" variant="bodyMedium" color={colors.onSurface}>
              {t("pleaseInclude")}
            </Text>
            <ul>
              <li>
                <Text variant="bodyMedium" color={colors.onSurface}>
                  {t("includeSpouse")}
                </Text>
              </li>
              <li>
                <Text variant="bodyMedium" color={colors.onSurface}>
                  {t("includeOtherParent")}
                </Text>
              </li>
            </ul>
          </div>
          <Stack gap={theme.spacing(2)} flex={1} alignItems="center">
            {familyData.family_members.map((parent) => (
              <ParentRow
                key={parent.id}
                name={
                  !parent.first_name && !parent.last_name
                    ? "Unknown name"
                    : `${parent.first_name} ${parent.last_name}`
                }
                onEdit={() => handleEditParent(parent.id)}
              />
            ))}
            <AddButton
              label={t("addParent")}
              backgroundColor={colors.secondaryContainer}
            />
          </Stack>
          <div
            style={{
              padding: theme.spacing(2, 3),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasicButton label={t("next")} onClick={handleNextClick} />
          </div>
        </Stack>
      </Container>
    </FullPageDialog>
  );
};
export default ParentsListPage;
