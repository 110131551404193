import _ from "lodash";
import { Navigate } from "react-router-dom";

import { useMyFamily } from "entities/Family/sdk";
import { CircularProgress } from "components/Loading";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const MissingInformationVerificationRedirectToParentPage = () => {
  const { data: family } = useMyFamily();

  const parentId: number | undefined = _.first(
    _.orderBy(family?.family_members, "id")
  )?.id;

  if (!parentId) {
    return (
      <div
        style={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  // If the family has no parents - reroute to missing parent info
  if (!parentId && Boolean(family)) {
    return <Navigate to={URLS.MISSING_INFORMATION_PARENTS} />;
  }

  return (
    <Navigate
      to={reverse(URLS.MISSING_INFORMATION_VERIFICATION_FOR_PARENT, {
        parentId,
      })}
    />
  );
};

export default MissingInformationVerificationRedirectToParentPage;
