import _ from "lodash";
import { AttentionIcon } from "icons";
import theme from "theme";

export const MissingInformationLabel = () => (
  <span
    style={{
      color: theme.palette.warning.main,
    }}
  >
    {" "}
    Missing Information
  </span>
);

export const useFormErrors = ({ formState }: { formState: any }) => {
  const fieldErrors = (field: any) => {
    return {
      error: !!formState.errors[field.name],
      helperText:
        // @ts-ignore
        formState.errors[field.name]?.message,
    };
  };
  return { fieldErrors };
};

export const useFormWarnings = ({
  formState,
  fieldsWithWarnings,
}: {
  formState: any;
  fieldsWithWarnings: string[] | undefined;
}) => {
  const hasWarning = (field: { value: any; name: string }): boolean => {
    return !!fieldsWithWarnings?.find((x) => x === field.name) && !field.value;
  };

  const fieldWarningsAndErrors = (field: any) => {
    return {
      focused: hasWarning(field) ? true : undefined,
      color: hasWarning(field) ? ("warning" as const) : undefined,
      // @ts-ignore The type of field is too deeply nested and wrapped within the context of the useForm call
      error: !!_.get(formState.errors, field.name),
      helperText:
        // @ts-ignore
        _.get(formState.errors, field.name + ".message") ??
        (hasWarning(field) && <MissingInformationLabel />),
      InputProps: {
        endAdornment: hasWarning(field) ? (
          <AttentionIcon style={{ width: "20px", height: "20px" }} />
        ) : undefined,
      },
    };
  };

  return {
    hasWarning,
    fieldWarningsAndErrors,
  };
};
