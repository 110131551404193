import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import DatePicker from "components/DatePicker";
import Box from "components/Box";
import useMediaQuery from "components/useMediaQuery";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

import {
  useGenderOptions,
  useLanguageOptions,
  useRelationshipOptions,
} from "entities/FamilyMember/sdk";

import { getDefaultLanguageValue } from "utils/language";
import DateInput from "components/DateInput";

import { colors } from "theme";
import { IDetailDocumentData } from "entities/Documents/sdk";

type IChild = IDetailDocumentData["family_data"]["children_data"][number];

interface IProps {
  initialValues?: IChild;
  onSave: (values: Partial<IChild>) => Promise<any>;
}

const ParsedChildForm = ({ initialValues, onSave }: IProps) => {
  const { t, i18n } = useTranslation();
  const defaultLanguage = getDefaultLanguageValue(i18n.language);
  const matches = useMediaQuery("(min-width:600px)");

  const genderOptions = useGenderOptions();
  const languageOptions = useLanguageOptions();
  const relationshipOptions = useRelationshipOptions();

  const { control, handleSubmit } = useForm<IChild>({
    defaultValues: {
      first_name: initialValues?.first_name || "",
      last_name: initialValues?.last_name || "",
      date_of_birth: initialValues?.date_of_birth
        ? dayjs(initialValues?.date_of_birth).format("MM/DD/YYYY")
        : undefined,
      gender: initialValues?.gender || undefined,
      relationship: initialValues?.relationship || undefined,
      date_when_child_care_is_needed:
        initialValues?.date_when_child_care_is_needed
          ? dayjs(initialValues.date_when_child_care_is_needed).format(
              "MM/DD/YYYY"
            )
          : dayjs().format("MM/DD/YYYY"),
      preferred_language: initialValues?.preferred_language || undefined,
      child_has_a_disability: initialValues?.child_has_a_disability || false,
      child_is_in_head_start_and_needs_more_hours_of_coverage:
        initialValues?.child_is_in_head_start_and_needs_more_hours_of_coverage ||
        false,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data: Partial<IChild>) => {
    setIsSubmitting(true);
    const formattedBirthDate = data.date_of_birth
      ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
      : undefined;

    const formattedDateWhenChildcareNeeded = dayjs(
      data.date_when_child_care_is_needed
    ).format("YYYY-MM-DD");

    const childData = {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      date_of_birth: formattedBirthDate,
      preferred_language: data.preferred_language,
      relationship: data.relationship,
      date_when_child_care_is_needed: data.date_when_child_care_is_needed
        ? formattedDateWhenChildcareNeeded
        : "",
      child_has_a_disability: data.child_has_a_disability,
      child_is_in_head_start_and_needs_more_hours_of_coverage:
        data.child_is_in_head_start_and_needs_more_hours_of_coverage,
    };

    onSave(childData)
      .catch(() => {
        const errorMessage = t("errorSavingData");
        toast.error(errorMessage);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Stack sx={{ alignItems: "center", flex: 1 }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ flex: 1 }}>
          <Stack
            sx={{ height: "100%" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack sx={{ width: "100%" }}>
              <Controller
                name="first_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("firstName")}
                    fullWidth
                    margin="normal"
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("lastName")}
                    fullWidth
                    margin="normal"
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("gender")}
                    options={genderOptions}
                    fullWidth
                    margin="normal"
                  />
                )}
              />

              <Controller
                name="preferred_language"
                control={control}
                defaultValue={defaultLanguage}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("preferredLanguage")}
                    options={languageOptions}
                    fullWidth
                    margin="normal"
                  />
                )}
              />
              <DateInput
                control={control}
                label={t("dateOfBirth")}
                name="date_of_birth"
                required={false}
              />

              <Controller
                name="relationship"
                control={control}
                defaultValue={relationshipOptions[0].value}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("relationship")}
                    options={relationshipOptions}
                    fullWidth
                    margin="normal"
                  />
                )}
              />
              <Controller
                name="date_when_child_care_is_needed"
                control={control}
                render={({ field }) => (
                  <Box sx={{ mt: "16px", mb: "8px" }}>
                    <DatePicker
                      {...field}
                      label={t("dateChildCare")}
                      initialValue={field.value}
                    />
                  </Box>
                )}
              />
              <Controller
                name="child_has_a_disability"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("childHasADisability")}
                  />
                )}
              />
              <Controller
                name="child_is_in_head_start_and_needs_more_hours_of_coverage"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("chilsIsInHeadStart")}
                  />
                )}
              />
            </Stack>
            <Stack
              alignItems={matches ? "center" : "flex-end"}
              sx={{
                width: "100%",
                borderTop: matches ? "1px solid" : "none",
                borderColor: colors.outline,
              }}
            >
              <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
                <BasicButton
                  buttonType="submit"
                  label={t("next")}
                  isDisabled={isSubmitting}
                />
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default ParsedChildForm;
