import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";
import Stack from "components/Stack";
import Container from "components/Container";
import Link from "components/Link";
import BasicButton from "components/BasicButton";
import AuthorizationListItem from "entities/Authorization/components/ListItem";

import { useMyFamily } from "entities/Family/sdk";
import { URLS } from "config/urls";
import { reverse } from "utils/urls";
import theme, { colors } from "theme";

const AuthorizationsListPage = () => {
  const { t } = useTranslation();
  const { data: family } = useMyFamily();
  const navigate = useNavigate();
  const authorizations = family?.vouchers || [];

  // Get all vouchers that have atleast one missing field in atleast one section
  const authorizationsWithWarnings =
    family?.missing_fields
      .filter(
        (voucher) => voucher.missing_fields.voucher_missing_fields.length > 0
      )
      .map((voucher) => voucher.voucher_id) || [];

  return (
    <FullPageDialog
      title={t("authorizations")}
      progressBarPercentage={50}
      backUrl={URLS.MISSING_INFORMATION_VERIFICATION}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm" sx={{ flex: 1 }}>
        <Stack spacing={2} sx={{ marginTop: 2 }}>
          {authorizations.map((authorization, index) => (
            <AuthorizationListItem
              key={index}
              authorization={{
                title: `Authorization ${index + 1}`,
                ...authorization,
              }}
              onEdit={() => {
                navigate(
                  reverse(URLS.MISSING_INFORMATION_AUTHORIZATION_EDIT, {
                    authorizationId: authorization.id,
                  })
                );
              }}
              hasIncompleteWarning={Boolean(
                authorizationsWithWarnings.find(
                  (authId) => authId === authorization.id
                )
              )}
            />
          ))}
        </Stack>
      </Container>
      <Stack
        sx={{
          margin: theme.spacing(4, 0),
          alignItems: "center",
          borderTop: "1px solid " + colors.outlineVariant,
          paddingTop: (theme) => theme.spacing(3),
        }}
      >
        <Link
          to={URLS.MISSING_INFORMATION_PLACEMENT_LIST}
          style={{ display: "contents" }}
        >
          <BasicButton label={t("next")} />
        </Link>
      </Stack>
    </FullPageDialog>
  );
};

export default AuthorizationsListPage;
