import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import BasicButton from "components/BasicButton";
import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";

import PlacementListItem from "entities/Placement/components/ListItem";

import { useMyFamily, IFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";
import { snakeCaseToCapitalisedText } from "utils/common";
import theme, { colors } from "theme";

const PlacementListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const placements = family?.vouchers.flatMap((voucher) => voucher.placements);

  const hasMissingFields = (
    placement: IFamily["vouchers"][number]["placements"][number]
  ) => {
    if (!family?.missing_fields) {
      return false;
    }

    return (
      family.missing_fields
        .flatMap((voucher) => voucher.missing_fields.placement_missing_fields)
        .filter(
          (placementMissingFields) =>
            placementMissingFields.id === placement.id &&
            placementMissingFields.missing_fields.length > 0
        ).length > 0
    );
  };

  const voucherAndChildWithMissingPlacement: Array<{
    voucher_id: number;
    missing_fields: {
      child_with_missing_placement: Array<{ child_id: number }>;
    };
  }> = family
    ? family.missing_fields.filter(
        (voucher) =>
          voucher.missing_fields.child_with_missing_placement.length > 0
      )
    : [];

  if (!placements) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("placements")}
      progressBarPercentage={60}
      backUrl={URLS.MISSING_INFORMATION_AUTHORIZATION_LIST}
      closeUrl={URLS.HOME}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 2,
          flex: 1,
        }}
      >
        {placements.map((placement) => (
          <PlacementListItem
            placement={placement}
            voucher={placement.voucher}
            onEdit={() => {
              navigate(
                reverse(URLS.MISSING_INFORMATION_PLACEMENT_EDIT, {
                  placementId: placement.id,
                })
              );
            }}
            hasMissingFields={hasMissingFields(placement)}
          />
        ))}
        <Link
          to={URLS.MISSING_INFORMATION_PLACEMENT_ADD}
          style={{ display: "components" }}
        >
          <AddButton
            label={t("addPlacement")}
            backgroundColor={colors.secondaryContainer}
          />
        </Link>
        {voucherAndChildWithMissingPlacement.length > 0 && (
          <Stack spacing={2} sx={{ marginTop: (theme) => theme.spacing(2) }}>
            {voucherAndChildWithMissingPlacement.map((voucher) => (
              <Text color="error">
                Missing placement for Authorization{" "}
                {snakeCaseToCapitalisedText(
                  family?.vouchers.find(
                    (familyVoucher) => familyVoucher.id === voucher.voucher_id
                  )?.type || ""
                )}{" "}
                for children:{" "}
                <b>
                  {family?.children
                    .filter(
                      (child) =>
                        voucher.missing_fields.child_with_missing_placement
                          .map((c) => c.child_id)
                          .indexOf(child.id) > -1
                    )
                    .map((child) => `${child.first_name} ${child.last_name}`)
                    .join(", ")}
                </b>
              </Text>
            ))}
          </Stack>
        )}
      </Container>
      <Stack
        sx={{
          margin: theme.spacing(4, 0),
          alignItems: "center",
          borderTop: "1px solid " + colors.outlineVariant,
          paddingTop: (theme) => theme.spacing(3),
        }}
      >
        <Link to={URLS.HOME}>
          <BasicButton label={t("next")} />
        </Link>
      </Stack>
    </FullPageDialog>
  );
};

export default PlacementListPage;
