import { useState } from "react";

import { CircleFlag } from "react-circle-flags";
import { useTranslation } from "react-i18next";
import IconButton from "components/IconButton";
import MenuItem from "components/MenuItem";

import { saveLanguage } from "utils/storage";
import Menu from "components/Menu";

const FlagLanguageSelector = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const getCurrentFlag = () => {
    const currentLanguage =
      languageOptions.find((option) => option.code === i18n.language) ||
      languageOptions[0];
    return <CircleFlag countryCode={currentLanguage.countryCode} height="24" />;
  };

  const languageOptions = [
    { code: "en", name: "English", countryCode: "us" },
    { code: "es", name: "Spanish", countryCode: "es" },
    { code: "kea", name: "Cape Verdean Creole", countryCode: "cv" },
    { code: "ht", name: "Haitian Creole", countryCode: "ht" },
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (langCode: string) => {
    i18n.changeLanguage(langCode);
    saveLanguage(langCode);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="language-button"
        aria-controls={open ? "language-menu" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {getCurrentFlag()}
      </IconButton>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "language-button",
        }}
      >
        {languageOptions.map((option) => (
          <MenuItem
            key={option.code}
            onClick={() => handleMenuItemClick(option.code)}
          >
            <CircleFlag countryCode={option.countryCode} height="24" />
            <span style={{ marginLeft: "10px" }}>{option.name}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FlagLanguageSelector;
