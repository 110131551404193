import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import AppBar from "components/AppBar";
import Box from "components/Box";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";
import useMediaQuery from "components/useMediaQuery";
import FlagLanguageSelector from "components/FlagLanguageSelector";

import { getTitle } from "utils/titles";
import { URLS } from "config/urls";

import { ArrowLeftIcon } from "icons";

import { colors } from "theme";

const AnonimousUserLayout = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();

  const title = getTitle(location.pathname);

  const goBack = () => {
    if (location.pathname === URLS.CHILD_CARE_NEEDS_DESCRIPTION) {
      navigate(URLS.INITIAL);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <AppBar
        sx={{
          paddingLeft: 0,
          height: "64px",
          backgroundColor: colors.surface,
          justifyContent: "center",
          boxShadow: "none",
          position: "fixed",
          top: 0,
          margin: 0,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            marginLeft: matches ? 0 : "25px",
            marginRight: matches ? 0 : "25px",
            borderBottom: `2px solid ${colors.outlineVariant}`,
            height: "100%",
          }}
          spacing={matches ? 0 : 6}
        >
          <Stack
            sx={{
              flex: 1,
              marginLeft: matches ? "35px" : "0px",
            }}
            direction="row"
            alignItems="center"
          >
            <IconButton onClick={goBack} sx={{ mr: "15px" }}>
              <ArrowLeftIcon style={{ fill: colors.onSurfaceVariant }} />
            </IconButton>
            <Text variant="titleLarge" color={colors.onSurfaceVariant}>
              {title}
            </Text>
          </Stack>

          <Stack>
            <FlagLanguageSelector />
          </Stack>
        </Stack>
      </AppBar>
      <Box
        sx={{
          p: matches ? "90px 25px" : "80px 25px 20px 25px",
          backgroundColor: colors.surface,
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default AnonimousUserLayout;
