import FlagLanguageSelector from "components/FlagLanguageSelector";
import IconButton from "components/IconButton";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";

import { ArrowLeftIcon } from "icons";
import theme, { colors } from "theme";

interface IProps {
  title?: React.ReactNode;
  progressBarPercentage?: number;
  backUrl?: string;
  closeUrl?: string;
}

const FullPageDialogWithLanguage = ({
  title,
  children,
  backUrl,
}: React.PropsWithChildren<IProps>) => {
  return (
    <Stack sx={{ height: "100vh" }}>
      <Stack
        direction="row"
        sx={{
          flex: "64px 0 0",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 4px",
          [theme.breakpoints.up("md")]: {
            padding: "0px 16px",
          },
        }}
      >
        {backUrl ? (
          <Link to={backUrl}>
            <IconButton size="large" sx={{ margin: "4px" }}>
              <ArrowLeftIcon style={{ fill: colors.onSurfaceVariant }} />
            </IconButton>
          </Link>
        ) : (
          <div />
        )}
        <Text
          variant="titleLarge"
          sx={{ fontWeight: 600, color: colors.onSurfaceVariant }}
        >
          {title}
        </Text>
        <FlagLanguageSelector />
      </Stack>
      <div
        style={{
          borderBottom: `1px solid ${colors.outlineVariant}`,
          margin: "0 16px",
        }}
      />
      {children}
    </Stack>
  );
};

export default FullPageDialogWithLanguage;
