import { useTranslation } from "react-i18next";

import BasicButton from "components/BasicButton";
import Container from "components/Container";
import Stack from "components/Stack";
import FullPageDialog from "components/FullPageDialog";
import Text from "components/Text";
import AddButton from "components/AddButton";
import ParentRow from "components/ParentRow";

import theme, { colors } from "theme";

import { URLS } from "config/urls";

import { useMyFamily } from "entities/Family/sdk";

const ChildreListPage = () => {
  const { t } = useTranslation();
  const { data: familyData } = useMyFamily();

  const handleEditParent = (parentId: number) => {
    console.log(parentId);
  };

  if (!familyData) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("children")}
      backUrl={URLS.VERIFY_INCOME_PARENTS_LIST}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm" sx={{ flex: 1, marginTop: theme.spacing(2) }}>
        <Stack
          spacing={2}
          sx={{ height: "100%", justifyContent: "space-between" }}
        >
          <div style={{ marginTop: theme.spacing(2) }}>
            <Text
              component="p"
              variant="titleMedium"
              color={colors.onSurface}
              sx={{ mb: theme.spacing(2) }}
            >
              {t("addChildrenInstruction")}
            </Text>
            <Text component="p" variant="bodyMedium" color={colors.onSurface}>
              {t("pleaseInclude")}
            </Text>
            <ul>
              <li>
                <Text variant="bodyMedium" color={colors.onSurface}>
                  {t("includeChildrenUnder18")}
                </Text>
              </li>
              <li>
                <Text variant="bodyMedium" color={colors.onSurface}>
                  {t("includeChildrenUnder24InSchool")}
                </Text>
              </li>
            </ul>
          </div>
          <Stack gap={theme.spacing(2)} flex={1} alignItems="center">
            {familyData.children.map((child) => (
              <ParentRow
                key={child.id}
                name={
                  !child.first_name && !child.last_name
                    ? "Unknown name"
                    : `${child.first_name} ${child.last_name}`
                }
                onEdit={() => handleEditParent(child.id)}
              />
            ))}
            <AddButton
              label={t("addChild")}
              backgroundColor={colors.secondaryContainer}
            />
          </Stack>
          <div
            style={{
              padding: theme.spacing(2, 3),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasicButton label={t("next")} />
          </div>
        </Stack>
      </Container>
    </FullPageDialog>
  );
};
export default ChildreListPage;
