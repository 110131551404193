import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import _ from "lodash";

import AddButton from "components/AddButton";
import FilterSearchRow from "components/FilterSearchRow";
import Stack from "components/Stack";
import Text from "components/Text";
import TablePagination from "components/TablePagination";

import { colors } from "theme";
import { URLS } from "config/urls";
import { reverse } from "utils/urls";
import { formatToCamelCase } from "utils/format";

import useReadableStatus, {
  CompletedStatus,
  useFamily,
} from "entities/FamilySpecialist/sdk";

const FamilyListPage = () => {
  const { t } = useTranslation();
  const { getReadableCompletedStatus } = useReadableStatus();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [queryParams, setQueryParams] = useState({
    action_needed: false,
    assigned_to_me: false,
    search: "",
    offset: page * rowsPerPage,
    limit: rowsPerPage,
  });
  const { data: familiesData } = useFamily(queryParams);
  const navigate = useNavigate();

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setQueryParams((prev) => ({
      ...prev,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    }));
  }, [page, rowsPerPage]);

  const openFamilyDetail = (id: number) => {
    if (id) {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_FAMILY_DETAIL, {
          id: id.toString(),
        })
      );
    }
  };

  const handleButtonClick = (
    event: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    if (event) {
      event.stopPropagation();
      window.open(
        "https://earlyedncareop.eec.state.ma.us/KinderWait/ActivityPages/ApplicationPages/AddNewApplication.aspx",
        "_blank"
      );
    }
  };

  const handleFilterChange = (
    actionNeeded: boolean,
    assignedToMe: boolean,
    searchText: string
  ) => {
    setQueryParams({
      action_needed: actionNeeded,
      assigned_to_me: assignedToMe,
      search: searchText,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
    });
  };

  return (
    <Stack
      justifyContent="flex-start"
      gap="20px"
      sx={{
        minHeight: "calc(100vh - 60px)",
        backgroundColor: colors.surface,
        padding: "30px",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ padding: "16px" }}
        justifyContent="space-between"
        gap="30px"
      >
        <Text variant="titleLarge" sx={{ mt: 2, mb: 1 }}>
          {t("families")}
        </Text>
        <FilterSearchRow onChange={handleFilterChange} />
      </Stack>
      {familiesData?.results.map((family) => (
        <Stack
          direction="row"
          gap="15px"
          justifyContent="space-between"
          alignItems="center"
          key={family.id}
          sx={{
            borderTop: "1px solid",
            borderColor: colors.outline,
            py: "10px",
            cursor: "pointer",
          }}
          onClick={() => openFamilyDetail(family.id)}
        >
          <Stack
            direction="row"
            gap="20px"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
          >
            <Text
              variant="titleMedium"
              fontWeight="bold"
              sx={{ mt: 2, mb: 1, flex: 1 }}
            >
              {family.preferred_contact.first_name}{" "}
              {family.preferred_contact.last_name}
            </Text>

            <Text variant="titleMedium" sx={{ mt: 2, mb: 1, flex: 1 }}>
              {getReadableCompletedStatus(
                family.vouchers[0]?.status as CompletedStatus
              )}
            </Text>
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1, flex: 1 }}>
              {family.vouchers && family.vouchers[0]
                ? t(formatToCamelCase(family.vouchers[0].type))
                : t("unknown")}
            </Text>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap="15px"
            justifyContent="flex-end"
            flex={1}
          >
            {_.some(
              family.vouchers,
              (voucher) =>
                voucher.status !== "ON_WAITLIST" && voucher.status !== "ISSUED"
            ) && (
              <Stack sx={{ marginTop: "16px", marginBottom: "8px" }}>
                <AddButton
                  label={t("addToWaitlist")}
                  onClick={handleButtonClick}
                />
              </Stack>
            )}
            <Text fontStyle="italic" sx={{ mt: 2, mb: 1 }}>
              {t("lastActivity")}: {family.preferred_contact.updated_at}
            </Text>
          </Stack>
        </Stack>
      ))}
      <TablePagination
        component="div"
        count={familiesData?.count || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        sx={{
          ".MuiTablePagination-toolbar": {
            justifyContent: "space-between",
            padding: "0px 24px",
          },
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
            {
              margin: 0,
            },
          ".MuiTablePagination-actions": {
            marginLeft: 0,
          },
          "& .MuiInputBase-input": {
            borderRadius: "4px",
            backgroundColor: colors.surfaceContainerLow,
            border: "1px solid #ced4da",
            padding: "10px 26px 10px 12px",
          },
          "& .MuiTablePagination-input": {
            position: "absolute",
            left: "135px",
          },
          "& .MuiTablePagination-spacer": {
            display: "none",
          },
        }}
      />
    </Stack>
  );
};

export default FamilyListPage;
