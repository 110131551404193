import { useState, useEffect } from "react";
import _ from "lodash";

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { URLS } from "config/urls";

import Home from "pages/Home";
import LoginPage from "pages/Login";
import InitialPage from "pages/Initial";
import SignUpPage from "pages/SignUp";
import RegistrationVerificationPage from "pages/RegistrationVerification";
import ChildCareNeedsPage from "pages/ChildCareNeeds";
import FamilyInformationPage from "pages/FamilyInformation";
import MagicLinkPage from "pages/MagicLink";
import Overview from "pages/FamilyInformation/components/Overview";
import AboutYou from "pages/FamilyInformation/components/AboutYou";
import YourStatus from "pages/FamilyInformation/components/YourStatus";
import MailingAddress from "pages/FamilyInformation/components/MailingAddress";
import Children from "pages/FamilyInformation/components/Children";
import AddChild from "pages/FamilyInformation/components/AddChild";
import AdditionalInformation from "pages/FamilyInformation/components/AdditionalInformation";
import Complete from "pages/FamilyInformation/components/Complete";
import LoginVerificationPage from "pages/LoginVerification";
import FamilySpecialistHome from "pages/FamilySpecialist/Home";
import FamilyListPage from "pages/FamilySpecialist/FamilyList";
import FamilyDetailPage from "pages/FamilySpecialist/FamilyDetail";
import GetHelpPage from "pages/GetHelp";
import ThankYouPage from "pages/ThankYou";
import ChildNeedsForm from "pages/ChildCareNeeds/components/ChildNeedsForm";
import CheckNeeds from "pages/ChildCareNeeds/components/CheckNeeds";
import UpdateInformation from "pages/ChildCareNeeds/components/UpdateInformation";
import IncomeVerification from "pages/FamilyInformation/components/IncomeVerification";
import HouseholdDefinitions from "pages/FamilyInformation/components/HouseholdDefinitions";
import AuthorizationType from "pages/FamilyInformation/components/AuthorizationType";
import PrimaryContact from "pages/FamilyInformation/components/PrimaryContact";
import DocumentsContainerPrimaryContact from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/PrimaryContact";
import DocumentsContainerParentStatus from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/ParentStatus";
import DocumentsContainerChildrenList from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/Children";
import DocumentsContainerParentEdit from "pages/FamilyInformation/components/ParsedParentEditPage";
import DocumentsContainerParentAdd from "pages/FamilyInformation/components/ParsedParentAddPage";
import DocumentsContainerChildrenAdd from "pages/FamilyInformation/components/ParsedChildAdd";
import DocumentsContainerChildrenEdit from "pages/FamilyInformation/components/ParsedChildEdit";
import DocumentContainerAuthorizationTypeEdit from "pages/FamilyInformation/components/AuthorizationTypeEdit";
import DocumentContainerPlacementList from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/ParsedPlacementList";
import DocumentContainerPlacementAdd from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/ParsedPlacementAdd";
import DocumentContainerPlacementEdit from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/ParsedPlacementEdit";
import DocumentContainerAssign from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/Assign";
import Parents from "pages/FamilyInformation/components/Parents";
import ParentAddAsSpecialistPage from "pages/FamilySpecialist/ParentAddAsSpecialistPage";
import SpecialCircumstance from "pages/FamilyInformation/components/SpecialCircumstance";
import MessagesListPage from "pages/FamilySpecialist/MessagesList";
import ExpiredMagicLink from "pages/ExpiredMagicLink";
import Admin from "pages/FamilySpecialist/Admin";

import PrimaryContactPage from "pages/FamilyInformation/PrimaryContactPage";
import ParentsListPage from "pages/FamilyInformation/ParentsListPage";
import ParentAddPage from "pages/FamilyInformation/ParentAddPage";
import ParentEditPage from "pages/FamilyInformation/ParentEditPage";
import ChildListPage from "pages/FamilyInformation/ChildListPage";
import ChildAddPage from "pages/FamilyInformation/ChildAddPage";
import ChildEditPage from "pages/FamilyInformation/ChildEditPage";

import MissingInformationPrimaryContactPage from "pages/MissingInformation/PrimaryContactPage";
import MissingInformationParentsListPage from "pages/MissingInformation/ParentsListPage";
import MissingInformationParentAddPage from "pages/MissingInformation/ParentAddPage";
import MissingInformationParentEditPage from "pages/MissingInformation/ParentEditPage";
import MissingInformationChildListPage from "pages/MissingInformation/ChildListPage";
import MissingInformationChildAddPage from "pages/MissingInformation/ChildAddPage";
import MissingInformationChildEditPage from "pages/MissingInformation/ChildEditPage";
import MissingInformationVerificationRedirectToParentPage from "pages/MissingInformation/VerificationRedirectToParentPage";
import MissingInformationVerificationPage from "pages/MissingInformation/VerificationPage";
import MissingInformationAuthorizationsListPage from "pages/MissingInformation/AuthorizationsListPage";
import MissingInformationAuthorizationEditPage from "pages/MissingInformation/AuthorizationEditPage";
import MissingInformationPlacementListPage from "pages/MissingInformation/PlacementListPage";
import MissingInformationPlacementAddPage from "pages/MissingInformation/PlacementAddPage";
import MissingInformationPlacementEditPage from "pages/MissingInformation/PlacementEditPage";

import VerifyIncomeFlowContactInformation from "pages/VerifyIncomeFlow/ContactInfoPage";
import VerifyIncomeChildCareNeeds from "pages/VerifyIncomeFlow/ChildCareNeedsPage";
import VerifyIncomeOverview from "pages/VerifyIncomeFlow/OverviewPage";
import VerifyIncomeParentsList from "pages/VerifyIncomeFlow/ParentsListPage";
import VerifyIncomeChildreList from "pages/VerifyIncomeFlow/ChildreListPage";

import Layout from "components/Layout";
import FamilyMemberMissingInfoLayout from "components/Layout/FamilyMemberMissingInfoLayout";
import FamilyMemberVerifyIncomeLayout from "components/Layout/FamilyMemberVerifyIncomeLayout";
import DocumentsLayout from "pages/FamilyInformation/components/DocumentsLayout";
import AnonimousUserLayout from "components/AnonimousUserLayout";

import { getSignedUser, UserType, stepToUrlMapping } from "entities/Auth/sdk";

import { CircularProgress } from "components/Loading";

const LoginGuard = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    getSignedUser()
      .then(() => {
        setSignedIn(true);
      })
      .catch((error) => {
        if (error?.response?.status === 403) {
          navigate("/");
        } else {
          // TODO
          navigate("/");
        }
      });
  }, []);

  if (signedIn) {
    return children;
  }
  return (
    <div
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const InitialRedirect = () => {
  useEffect(() => {
    getSignedUser()
      .then((response) => {
        const user = response.data;
        if (user.user_type === UserType.FAMILY_SPECIALIST) {
          window.location.href = URLS.FAMILY_SPECIALIST_FAMILY_LIST;
        }

        if (user.user_type === UserType.FAMILY_MEMBER) {
          window.location.href = _.get(
            stepToUrlMapping,
            user.family_member_flow_step,
            URLS.HOME
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.href = "/welcome";
        }
      });
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <InitialRedirect />,
  },
  {
    path: "/welcome",
    element: <InitialPage />,
  },
  {
    path: "",
    element: <AnonimousUserLayout />,
    children: [
      {
        path: URLS.LOGIN,
        element: <LoginPage />,
      },
      {
        path: URLS.SIGNUP,
        element: <SignUpPage />,
      },
      {
        path: URLS.REGISTRATION_VERIFY,
        element: <RegistrationVerificationPage />,
      },
      {
        path: URLS.LOGIN_VERIFY,
        element: <LoginVerificationPage />,
      },
      {
        path: URLS.MAGIC_LINK,
        element: <MagicLinkPage />,
      },
      {
        path: URLS.GET_HELP,
        element: <GetHelpPage />,
      },
      {
        path: URLS.THANK_YOU,
        element: <ThankYouPage />,
      },
      {
        path: URLS.EXPIRED_MAGIC_LINK,
        element: <ExpiredMagicLink />,
      },
    ],
  },
  {
    path: "/missing-information",
    element: (
      <LoginGuard>
        <FamilyMemberMissingInfoLayout />
      </LoginGuard>
    ),
    children: [
      {
        path: "primary-contact",
        element: <MissingInformationPrimaryContactPage />,
      },
      { path: "parents", element: <MissingInformationParentsListPage /> },
      { path: "add-parent", element: <MissingInformationParentAddPage /> },
      {
        path: "parent/:parentId/edit",
        element: <MissingInformationParentEditPage />,
      },
      { path: "children", element: <MissingInformationChildListPage /> },
      { path: "add-child", element: <MissingInformationChildAddPage /> },
      {
        path: "child/:childId/edit",
        element: <MissingInformationChildEditPage />,
      },
      {
        path: "verification",
        element: <MissingInformationVerificationRedirectToParentPage />,
      },
      {
        path: "verification/parent/:parentId",
        element: <MissingInformationVerificationPage />,
      },
      {
        path: "authorizations",
        element: <MissingInformationAuthorizationsListPage />,
      },
      {
        path: "authorization/:authorizationId/edit",
        element: <MissingInformationAuthorizationEditPage />,
      },
      {
        path: "placements",
        element: <MissingInformationPlacementListPage />,
      },
      {
        path: "placements/add",
        element: <MissingInformationPlacementAddPage />,
      },
      {
        path: "placement/:placementId/edit",
        element: <MissingInformationPlacementEditPage />,
      },
    ],
  },
  {
    path: "/verify-income",
    element: (
      <LoginGuard>
        <FamilyMemberVerifyIncomeLayout />
      </LoginGuard>
    ),
    children: [
      {
        path: "contact-information",
        element: <VerifyIncomeFlowContactInformation />,
      },
      {
        path: "child-care-needs",
        element: <VerifyIncomeChildCareNeeds />,
      },
      {
        path: "overview",
        element: <VerifyIncomeOverview />,
      },
      {
        path: "parents-list",
        element: <VerifyIncomeParentsList />,
      },
      {
        path: "children-list",
        element: <VerifyIncomeChildreList />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <LoginGuard>
        <Layout />
      </LoginGuard>
    ),
    loader: () => <div>Loading ...</div>,
    errorElement: <div>Oops, something went wrong...</div>,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "child-care-needs/",
        element: <ChildCareNeedsPage />,
        children: [
          { path: "", element: <Navigate to="description" replace /> },
          { path: "description", element: <ChildNeedsForm /> },
          { path: "check-needs", element: <CheckNeeds /> },
          { path: "update-information", element: <UpdateInformation /> },
        ],
      },
      {
        path: "family-information/",
        element: <FamilyInformationPage />,
        children: [
          { path: "", element: <Navigate to="overview" replace /> },
          { path: "overview", element: <Overview /> },
          { path: "about-you", element: <AboutYou /> },
          { path: "your-status", element: <YourStatus /> },

          { path: "primary-contact", element: <PrimaryContactPage /> },
          { path: "parents", element: <ParentsListPage /> },
          { path: "add-parent", element: <ParentAddPage /> },
          { path: "parent/:parentId/edit", element: <ParentEditPage /> },
          { path: "mailing-address", element: <MailingAddress /> },
          { path: "children", element: <ChildListPage /> },
          { path: "add-child", element: <ChildAddPage /> },
          { path: "child/:childId/edit", element: <ChildEditPage /> },
          { path: "income-verification", element: <IncomeVerification /> },

          { path: "household-definitions", element: <HouseholdDefinitions /> },
          { path: "special-circumstances", element: <SpecialCircumstance /> },
          {
            path: "additional-information",
            element: <AdditionalInformation />,
          },
          { path: "complete", element: <Complete /> },
          { path: "*", element: <Navigate to="overview" replace /> },
        ],
      },
    ],
  },
  {
    path: "/family-specialist/",
    element: (
      <LoginGuard>
        <Layout />
      </LoginGuard>
    ),
    loader: () => <div>Loading ...</div>,
    errorElement: <div>Oops, something went wrong...</div>,
    children: [
      {
        path: "home",
        element: <FamilySpecialistHome />, // unused
      },
      {
        path: "family-list",
        element: <FamilyListPage />,
      },
      {
        path: "family-detail/:id",
        element: <FamilyDetailPage />,
      },
      {
        path: "family-information/",
        element: <FamilyInformationPage />,
        children: [
          { path: "", element: <Navigate to="authorization" replace /> },
          { path: "authorization", element: <AuthorizationType /> },
          { path: "primary-contact", element: <PrimaryContact /> },
          { path: "family/:familyId/your-status", element: <YourStatus /> },
          { path: "family/:familyId/parents", element: <Parents /> },
          {
            path: "family/:familyId/add-parent",
            element: <ParentAddAsSpecialistPage />,
          },
          {
            path: "family/:familyId/mailing-address",
            element: <MailingAddress />,
          },
          { path: "family/:familyId/children", element: <Children /> },
          { path: "family/:familyId/add-child", element: <AddChild /> }, // used
          {
            path: "family/:familyId/special-circumstances",
            element: <SpecialCircumstance />,
          },
          {
            path: "family/:familyId/additional-information",
            element: <AdditionalInformation />,
          },
          { path: "family/:familyId/complete", element: <Complete /> },
          {
            path: "documents/:documentId/",
            element: <DocumentsLayout />,
            children: [
              {
                path: "primary-contact",
                element: <DocumentsContainerPrimaryContact />,
              },
              {
                path: "parents",
                element: <DocumentsContainerParentStatus />,
              },
              {
                path: "parents/:parentId/edit",
                element: <DocumentsContainerParentEdit />,
              },
              {
                path: "parents/add",
                element: <DocumentsContainerParentAdd />,
              },
              {
                path: "children",
                element: <DocumentsContainerChildrenList />,
              },
              {
                path: "parsed-child/add",
                element: <DocumentsContainerChildrenAdd />,
              },
              {
                path: "parsed-child/:parsedChildId/edit",
                element: <DocumentsContainerChildrenEdit />,
              },
              {
                path: "authorization-type",
                element: <DocumentContainerAuthorizationTypeEdit />,
              },
              {
                path: "placement",
                element: <DocumentContainerPlacementList />,
              },
              {
                path: "placement/add",
                element: <DocumentContainerPlacementAdd />,
              },
              {
                path: "placement/:placementId/edit",
                element: <DocumentContainerPlacementEdit />,
              },
              {
                path: "family/:familyId/assign-specialist",
                element: <DocumentContainerAssign />,
              },
            ],
          },
          { path: "*", element: <Navigate to="authorization" replace /> },
        ],
      },
      {
        path: "messages-list",
        element: <MessagesListPage />,
      },
      {
        path: "admin",
        element: <Admin />,
      },
    ],
  },
]);

const Routes = () => <RouterProvider router={router} />;

export default Routes;
