import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Box from "components/Box";
import Chip from "components/Chip";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import Container from "components/Container";
import BasicButton from "components/BasicButton";
import FullPageDialog from "components/FullPageDialog";
import FileUploadBox from "components/FileUploadBox";
import { CircularProgress } from "components/Loading";

import { uploadAndParseDocument } from "entities/Documents/sdk";
import { useMyFamily, getMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { colors } from "theme";
import { CheckIcon, AttentionIcon } from "icons";
import { reverse } from "utils/urls";

const MissingInformationVerificationPage = () => {
  const { parentId: parentIdParam } = useParams<{ parentId: string }>();
  const parentId = parseInt(String(parentIdParam));
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);

  const { data: family, mutate: reloadFamily } = useMyFamily();
  const parent = family?.family_members.find(
    (parent) => parent.id === parentId
  );

  const allFilesAreParsed: boolean =
    parent?.parsed_parent_documents.filter((document) => !document.is_ready)
      .length === 0;

  const handleFileSelect = (file: any) => {
    setIsUploading(true);
    uploadAndParseDocument({ parentId, file }).then(() => {
      setIsUploading(false);
      reloadFamily();

      const interval = setInterval(
        () =>
          getMyFamily().then((polledFamily) => {
            const polledParent = polledFamily.family_members.find(
              (parent) => parent.id === parentId
            );
            const allFilesAreParsedInPolling: boolean =
              polledParent?.parsed_parent_documents.filter(
                (document) => !document.is_ready
              ).length === 0;

            if (allFilesAreParsedInPolling) {
              clearInterval(interval);
              reloadFamily();
            }
          }),
        2000
      );
    });
  };

  if (!parent) {
    return null;
  }

  /* <Routing to previous and next parent> */
  const parentsOrdered = _.orderBy(family?.family_members, "id");
  const parentsVerificationsLeft = _.drop(
    _.dropWhile(parentsOrdered, (parent) => parent.id !== parentId),
    1
  );
  const nextParent = _.first(parentsVerificationsLeft);
  const parentsVerificationsDone = _.takeWhile(
    parentsOrdered,
    (parent) => parent.id !== parentId
  );
  const previousParent = _.last(parentsVerificationsDone);
  /* </Routing to previous and next parent> */

  const identityConfirmed =
    parent.parsed_parent_documents.filter(
      (document) =>
        document.document_type === "IDENTITY" && document.is_verified === true
    ).length > 0;

  return (
    <FullPageDialog
      title={t("verifyInformation")}
      progressBarPercentage={40}
      backUrl={
        previousParent
          ? reverse(URLS.MISSING_INFORMATION_VERIFICATION_FOR_PARENT, {
              parentId: previousParent.id,
            })
          : URLS.MISSING_INFORMATION_CHILDREN
      }
      closeUrl={URLS.HOME}
    >
      <Stack
        sx={{
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            margin: "30px auto",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Stack spacing={2}>
            <Text variant="titleMedium">
              We need to verify certain information in order to complete a child
              care financial assistance voucher. In your case, we need to
              verify:
            </Text>
            <Text variant="titleMedium" sx={{ color: "#675B57" }}>
              Proof of identification for {parent.first_name} {parent.last_name}
            </Text>
            <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
              Please upload a picture of your photo ID.
            </Text>

            <Text variant="titleMedium">
              Common IDs include: Driver's license, passport, work or school ID.
            </Text>

            <Text variant="titleSmall" sx={{ color: "#B30838" }}>
              If you do not have a photo ID, you may upload 2 forms of non-photo
              ID.
            </Text>

            <Stack spacing={1} direction="row" sx={{ alignItems: "center" }}>
              <Divider sx={{ flex: 1 }} />
              <Text variant="titleMedium" sx={{ fontWeight: 600 }}>
                Upload The Required Documents
              </Text>
              <Divider sx={{ flex: 1 }} />
            </Stack>
            {parent.parsed_parent_documents.map((document) => (
              <Box
                sx={{
                  padding: (theme) => theme.spacing(2, 0),
                  borderBottom: "1px solid #CFC8C3",
                }}
              >
                {document.file.original_file_name}
              </Box>
            ))}
            {isUploading && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress
                  thickness={8}
                  size={48}
                  sx={{ color: "#DA2C52" }}
                />
              </div>
            )}

            {!allFilesAreParsed && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CircularProgress
                  thickness={8}
                  size={48}
                  sx={{ color: "#DA2C52" }}
                />
                <Text variant="labelLarge" sx={{ marginTop: "16px" }}>
                  Verifying Documents
                </Text>
              </div>
            )}

            {identityConfirmed && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Chip
                  icon={<CheckIcon />}
                  label={
                    <Text sx={{ fontWeight: "bold" }}>Identity Verified</Text>
                  }
                  sx={{ backgroundColor: colors.secondaryContainer }}
                />
              </div>
            )}
            {!identityConfirmed && allFilesAreParsed && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>
                  <AttentionIcon />
                </div>
                <Text color="error">
                  Could not verify identity.
                  <br />
                  Please submit a new file.
                </Text>
              </div>
            )}

            <FileUploadBox file={null} setFile={handleFileSelect} />
          </Stack>
        </Container>
        <Stack
          sx={{
            borderTop: "1px solid " + colors.outline,
            padding: (theme) => theme.spacing(3, 0),
            alignItems: "center",
          }}
        >
          <Link
            to={
              nextParent
                ? reverse(URLS.MISSING_INFORMATION_VERIFICATION_FOR_PARENT, {
                    parentId: nextParent.id,
                  })
                : URLS.MISSING_INFORMATION_AUTHORIZATION_LIST
            }
          >
            <BasicButton label={t("next")} />
          </Link>
        </Stack>
      </Stack>
    </FullPageDialog>
  );
};

export default MissingInformationVerificationPage;
